<template>
    <section class="pt-3">
        <div class="row mx-0 align-items-center mb-3">
            <i class="icon-creditos text-general f-17 ml-3" />
            <div class="col-3">
                <el-select v-model="idCuenta" class="w-100" size="small" @change="listarPagos">
                    <el-option v-for="item in cuentas" :key="item.id" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
            <router-link :to="{name: 'admin.tesoreria.pasarela-pagos.cuentas'}">
                <div class="btn-pasarela border br-8">
                    <el-tooltip content="Editar Cuentas" placement="bottom" effect="light">
                        <i class="icon-pencil-outline text-general f-20" />
                    </el-tooltip>
                </div>
            </router-link>
        </div>
        <div v-if="idCuenta === null" class="row text-general-red justify-center bg-gris mt-2 br-10 mx-3 py-2">
            Por favor seleccione una cuenta
        </div>
        <div v-show="idCuenta" class="row mx-0 pt-3">
            <div class="col-12">
                <tabla-general
                :data="dataTable"
                alto="calc(100vh - 268px)"
                class="cr-pointer"
                class-header="text-general f-16 f-500"
                @filaFuncion="openDetalle"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col-auto f-18 text-general f-600">
                            Pagos realizados por payU
                        </div>
                        <div class="col d-flex">
                            <div class="bg-light-f5 ml-auto border h-28px cr-pointer d-middle px-2 br-20 text-general position-relative" @click="filtro">
                                <div class="rounded-circle bg-general position-absolute" style="width:8px;height:8px;right:0px;top:-2px;" />
                                <i class="icon-filter-outline f-16" />
								<p class="f-14">Filtrar</p>
                            </div>
                        </div>
                    </template>

                    <el-table-column width="80">
                        <template slot-scope="{row}">
                            <div class="row mx-0 justify-center">
                                <el-tooltip :content="tipo(row)" placement="bottom" effect="light">
                                    <i :class="`icon-${iconos(row)}`" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pedido" min-width="100">
                        <template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light">{{ row.id_entrega ? ' - ' : row.id_registro }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="usuario" label="Nombre" min-width="150">
                        <template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light">{{ row.usuario }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="usuario" label="Fecha Pago" min-width="130">
                        <template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light text-capitalize">{{ row.created_at | helper-fecha('DD MMM Y') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cedis" label="Cedis" min-width="120">
						<template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light" style="word-break: normal">{{ row.cedis }}</p>
                        </template>
					</el-table-column>
                    <el-table-column prop="reference_sale" label="Ref. de Pago" min-width="120">
						<template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light">{{ row.reference_sale }}</p>
                        </template>
					</el-table-column>
                    <el-table-column label="Valor Pagado" min-width="130">
                        <template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light">{{ separadorNumero(row.value,0) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payment_method_name" label="Medio de Pago" min-width="140">
						<template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light">{{ row.payment_method_name }}</p>
                        </template>
					</el-table-column>
                    <el-table-column label="Estado" width="200">
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <div :class="`${row.state_pol == 6 || row.state_pol == 5 || row.state_pol == 9 ? 'bg-pink text-white' : row.state_pol == 4 ? 'bg-green text-white' : 'bg-light-f5 border text-general2'}  f-14 px-2 br-4 text-center rounded-pill`" style="width:90px;">
                                    <p v-text="row.state_pol == 6 || row.state_pol == 5 || row.state_pol == 9 ? 'Rechazado' : row.state_pol == 4 ? 'Aprobado' : 'Pendiente'" />
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-filtro-pasarela ref="modalFiltroPasarela" :id-cuenta="idCuenta" @filtrar="setTabla" />
        <modal-detalle-pago ref="modalDetallePago" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pagos from "~/services/pasarela-tesoreria/pagos"
export default {
    components: {
        modalFiltroPasarela: () => import('./partials/modalFiltroPasarela'),
        modalDetallePago: () => import('./partials/modalDetallePago')
    },
    data(){
        return {
            dataTable: [],
            idCuenta:null,
            cuentas:[]


        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch:{
        id_cedis(val){
            this.idCuenta = null
            this.listarCuentas()
        }
    },
    mounted(){
        if(this.id_cedis != null){
            this.listarCuentas()
        }
    },
    methods: {
        async listarCuentas(){
            try {
                const {data} = await Pagos.selectCuentas()
                this.cuentas = data.cuentas
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarPagos(){
            try {
                if(this.idCuenta === null) return
                const {data} = await Pagos.listarPagos(this.idCuenta)
                this.dataTable = data.pagos

            } catch (e){
                this.error_catch(e)
            }
        },
        iconos(row){
            if(row.id_entrega) return 'comision text-success f-17';
            if(row.cliente) return 'cliente text-general f-20';
            if(!row.cliente) return 'leechero-cap text-general-red f-17';
        },
        tipo(row){
            if(row.id_entrega) return "Entrega Dinero";
            if(row.cliente) return 'Pedido Cliente';
            if(!row.cliente) return 'Pedido Vendedor';
        },
        filtro(){
            this.$refs.modalFiltroPasarela.toggle();
        },
        openDetalle(row){
            this.$refs.modalDetallePago.toggle(row)
        },
        setTabla(data){
            this.dataTable = data
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-pasarela{
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
}
.bg-pink{
    background-color: #FF5A60;
}
.bg-green{
    background-color: #28D07B;
}
</style>
