import axios from 'axios'

const Pagos = {
    selectCuentas(){
        return axios.get(`pasarelas/select-cuentas`)
    },
    listarPagos(id_cuenta){
        return axios.get(`pasarelas/cuentas/${id_cuenta}/pagos`)
    },
    listarCuentas(){
        return axios.get(`pasarelas/cuentas`)
    },
    detallePago(id_pago){
        return axios.get(`pasarelas/pagos/${id_pago}/detalle`)
    },
    crearCuenta(form){
        return axios.post(`pasarelas/cuentas/create`,form)
    },
    listarCedis(id_cuenta){
        return axios.get(`pasarelas/cuentas/${id_cuenta}/cedis`)
    },
    cambioEstadoCedis(form){
        return axios.put(`pasarelas/cuentas/cedis/estado/update`,form)
    },
    cambioTiempoCancelacionCedis(form){
        return axios.put(`pasarelas/cuentas/cedis/tiempo/update`,form)
    },
    getMediosDepago(id_cuenta){
        return axios.get(`pasarelas/cuentas/${id_cuenta}/medios-pago`)
    },
    filtroPagos(id_cuenta, params){
        return axios.get(`pasarelas/cuentas/${id_cuenta}/pagos/filtro`,{params})
    },
    cambiarEstadoCuenta(id_cuenta){
        return axios.put(`pasarelas/cuentas/${id_cuenta}/estado`)
    },
    eliminarCuenta(id_cuenta){
        return axios.delete(`pasarelas/cuentas/${id_cuenta}/delete`)
    },
    editarCuenta(id_cuenta, form){
        return axios.put(`pasarelas/cuentas/${id_cuenta}`,form)
    },
    eliminarCedisCuenta(id){
        return axios.delete(`pasarelas/cuentas/cedis/${id}`)
    },
}

export default Pagos
